/**
 * Humanise text
 * @description Convert text to human readable
 */
export function humanise(text: string): string {
  return text
    .replace(/_/g, ' ') // replace underscores with spaces
    .replace(/([A-Z])/g, ' $1') // add a space before each uppercase letter
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (word) => word.toUpperCase()) // capitalize each word
    .replace(/\s+/g, ' ') // replace multiple spaces with a single space
}

export function formatTime(time: string): string {
  try {
    const [hours, minutes] = time.split(':')
    const hour = parseInt(hours)
    const minute = parseInt(minutes)
    const ampm = hour >= 12 ? 'PM' : 'AM'
    const displayHour = hour % 12 || 12
    const displayMinute = minute.toString().padStart(2, '0')
    return `${displayHour}:${displayMinute} ${ampm}`
  } catch (e) {
    console.warn('Error formatting time', time, e)
    return time
  }
}
